@font-face {
    font-family: PangMenZhengDao;
    src: url("../../../../../assets/PangMenZhengDaoBiaoTiTi-1.ttf");
}

@font-face {
    font-family: D-DIN-PRO-Bold;
    src: url("../../../../../assets/D-DIN-PRO-700-Bold.ttf");
}


.container {
    display: flex;

    .item {
        padding: 12px;
        .content01 {
            color: #A4DAFE;
            background-color: rgba(82, 122, 255, 0.12);
            font-size: 14px;
            padding: 8px;
        }

        position: relative;
        flex: 1;
        .img {
            display: flex;
            align-items: center;
            margin-bottom: 7px;

            img {
                height: 24px;
                width: 24px;
                margin-right: 4px;
            }

            span {
                font-family: AlibabaPuHuiTi-Medium;
                font-size: 20px;
                background: linear-gradient(180deg, #ffffff 0%, #25a6ff 100%);
                background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: bold;
            }
        }

        .content {
            cursor: pointer;
            >div.box{
                display: inline-block;
                width: 50%;

            }
            >div{
                .item_title {
                    font-size: 14px;
                    margin-top: 8px;
                }

                .con {
                    background: linear-gradient(180deg, #ffffff 0%, #ff7167 100%);
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;
                    margin-top: 16px;
                    .span1 {
                        font-size: 20px;
                        font-family: D-DIN-PRO-Medium;
                    }

                    .span2 {
                        font-size: 14px;
                        -webkit-text-fill-color: white;
                    }

                    .down {
                        display: flex;
                        margin-right: 12px;
                        align-items: center;
                        flex-shrink: 0;
                        img {
                            width: 12px;
                            height: 12px;
                            margin-right: 2px;
                        }

                        span {
                            &:nth-of-type(1) {
                                font-size: 20px;
                                background: linear-gradient(180deg, #ffffff 0%, #25a6ff 100%);
                                background-clip: text;
                                -webkit-text-fill-color: transparent;
                                font-family: D-DIN-PRO-Medium;
                                line-height: 20px;
                            }
                            &:nth-of-type(2){
                                font-size: 20px;
                                background: linear-gradient(180deg, #ffffff 0%, #25a6ff 100%);
                                background-clip: text;
                                -webkit-text-fill-color: transparent;
                                font-family: D-DIN-PRO-Medium;
                                margin-left: 2px;
                                line-height: 23px;
                            }
                            &:nth-of-type(3){
                         
                                -webkit-text-fill-color: white;
                     
                            }
                        }
                    }
                }
            }
            .mt{
                margin-top: 4px;
            }
        }
    }

    .modal {
        display: flex;
        flex-direction: column;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 100;

        .modalContent {
            height: 807px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                display: none;
            }

            li {
                line-height: 48px;
                cursor: pointer;
                display: flex;
                padding-left: 16px;

                &:nth-child(even) {
                    background: rgba(78, 90, 105, 0.4);
                }

                span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                &:hover {
                    box-shadow: inset 0px 0px 16px 0px rgba(40, 167, 255, 0.8);
                }

                .statusOn {
                    color: #ffb900;
                }

                .statusIn {
                    color: #26a6ff;
                }
                .down{
                    color: #10df9d;
                }
            }
        }

        // 这是选择状态的ul，本应该是模态框的，临时处理一下
        ul {
            right: 320px !important;
        }
    }

    .detailModal {
        position: absolute;
        top: 50px;
        right: 0px;
    }
}