.root-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #001934;
    overflow: hidden;
    .user-dropdown {
      line-height: 48px;
      position: absolute;
      left: 20px;
      .drop-area {
          cursor: pointer;
          font-size: 18px;
          font-family: D-DIN-PRO-SemiBold, D-DIN-PRO;
          font-weight: 600;
      }
      .dropdown-icon {
          margin-left: 24px;
          width: 16px;
      }
      .dropup-icon {
          margin-left: 24px;
          width: 16px;
          transform: rotate(180deg);
      }
      .phone-text {
          position: relative;
          top: 1px;
      }
      .head-icon {
          width: 32px;
          margin-right: 8px;
      }
      .log-out {
          position: relative;
          left: 46px;
          top: -6px;
          cursor: pointer;
          width: 142px;
          height: 40px;
          line-height: 38px;
          text-align: center;
          background: #074e9e;
          box-shadow: inset 0px 0px 16px 0px rgba(37, 167, 255, 0.8);
          border-radius: 4px;
          border: 1px solid rgba(37, 167, 255, 0.6);
          font-size: 18px;
          font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
          font-weight: 500;
          color: #ffffff;
      }
  }
    > header {
        min-height: 92px;
        padding: 0 20px;
        // background: url(../../assets/head.webp) no-repeat;
        display: flex;

        .back-to-home {
            position: absolute;
            top: 12px;
            display: flex;
            align-items: center;
            line-height: 24px;
            img {
                margin-right: 9px;
            }
            font-size: 18px;
            font-weight: bold;
            background: #ffffff linear-gradient(180deg, #fdffff 0%, #6ab3f5 100%);
            background-clip: text;
            -webkit-text-fill-color: transparent;
            cursor: pointer;
        }
        .container-title{
            position: absolute;
            left: 50%;
            top: 10px;
            transform: translate(-50%);
            font-size: 32px;

        }
        .update-time {
            position: absolute;
            left: 50%;
            top: 55px;
            transform: translate(-50%);
            // font-family: AlibabaPuHuiTi-Regular;
                font-family: D-DIN-PRO-SemiBold, D-DIN-PRO;

            font-size: 14px;

            color: #91d0f2;
            line-height: 20px;
            text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);

            // .time {
                // font-family: DS-DIGIB;
                // font-family: D-DIN-PRO-SemiBold, D-DIN-PRO;
            // }
        }

        .local-date {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: absolute;
            height: 46px;
            top: 0;
            right: 20px;
            & > :first-child {
                height: 26px;
                font-size: 18px;
                font-family: D-DIN-PRO-SemiBold, D-DIN-PRO;
                font-weight: 600;
                color: #ffffff;
                line-height: 26px;
            }
            & > :last-child {
                height: 16px;
                font-size: 12px;
                font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
                font-weight: 400;
                color: #d0deee;
                line-height: 16px;
            }
        }
    }
    > main{
        flex: 1;
        height: 1px;
    }
}
