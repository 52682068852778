.change-record {
    background: rgba(1, 24, 54, 0.97);
    box-shadow: inset 0px 1px 40px 0px #006091;
    border-radius: 8px;
    backdrop-filter: blur(2px);
    width: 48vw;
    height: 86vh;
    position: fixed;
    padding: 28px 24px;
    top: 0;
    right: 0;
    z-index: 11;
    .report-title {
        margin-bottom: 24px;
        .title-text {
            font-size: 22px;
            font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
            color: #ffffff;
            background: linear-gradient(180deg, #fdffff 0%, #6ab3f5 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
    .close-img {
        position: absolute;
        top: 18px;
        right: 16px;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
    .modal-content {
        height: 78vh;
        overflow-y: auto;
        .record-item {
            margin-bottom: 40px;
            position: relative;
            .item-tail {
                position: absolute;
                top: 20px;
                left: 9px;
                height: calc(100% + 28px);
                border-left: 1px solid #5ce1ff;
            }
            .record-time {
                margin-bottom: 16px;
                .time-label {
                    font-size: 18px;
                    font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
                    color: #5ce1ff;
                    background: linear-gradient(
                        180deg,
                        #ffffff 0%,
                        #25a7ff 100%
                    );
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                .time-text {
                    font-size: 16px;
                    font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
                    font-weight: 500;
                    color: #ffffff;
                }
                .dot-img {
                    margin-right: 16px;
                    width: 20px;
                    display: inline-block;
                }
                .item-label {
                    padding: 4px 8px;
                    font-size: 14px;
                    border-radius: 2px;
                    margin-left: 24px;
                }
                .in-label {
                    background: rgba(92, 255, 181, 0.2);
                    color: #5cffb5;
                }
                .out-label {
                    background: rgba(255, 113, 31, 0.2);
                    color: #ff711f;
                }
            }
            .record-content {
                font-size: 16px;
                color: #ffffff;
                background: linear-gradient(
                    360deg,
                    rgba(34, 62, 97, 0.8) 0%,
                    rgba(25, 37, 62, 0.08) 100%
                );
                border-radius: 4px;
                padding: 16px;
                margin-left: 38px;
                .company-row {
                    margin-bottom: 24px;
                }
                .staff-item {
                    background: linear-gradient(
                        360deg,
                        rgba(34, 62, 97, 0.8) 0%,
                        rgba(25, 37, 62, 0.08) 100%
                    );
                    border-radius: 4px;
                    border: 1px solid;
                    border-image: linear-gradient(
                            180deg,
                            rgba(37, 167, 255, 0.24),
                            rgba(11, 192, 224, 0.24)
                        )
                        1 1;
                    padding: 12px;
                    margin-bottom: 12px;
                }
                .item-title {
                    font-size: 16px;
                    font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
                    font-weight: 500;
                    color: #ffffff;
                    background: linear-gradient(
                        180deg,
                        #fdffff 0%,
                        #6ab3f5 100%
                    );
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    margin-bottom: 8px;
                }
                .record-row {
                    display: flex;
                }
                .record-row-f {
                    display: flex;
                    flex-wrap: wrap;
                }
                .row-item {
                    margin-bottom: 8px;
                    width: 33.3333%;
                }
                .row-item2 {
                    margin-bottom: 8px;
                    width: 66.667%;
                }
            }
        }
        /*滚动条样式*/
        &::-webkit-scrollbar {
            width: 4px;
            /*height: 4px;*/
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.6);
            background: rgba(255, 255, 255, 0.6);
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            border-radius: 0;
            background: rgba(0, 0, 0, 0.1);
        }
    }
    .empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        .empty-img {
            width: 113px;
            padding-top: 40px;
            margin-bottom: 8px;
        }
        .empty-text {
            font-size: 16px;
            font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
            color: #ffffff;
        }
    }
}
