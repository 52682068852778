.build-info {
    position: relative;
    display: flex;
    flex-direction: column;
    background: linear-gradient(360deg,
            rgba(34, 62, 97, 1) 0%,
            rgba(25, 37, 62, 1) 100%);
    border-radius: 8px;
    border: 1px solid;
    border-image: linear-gradient(180deg,
            rgba(37, 167, 255, 0.24),
            rgba(11, 192, 224, 0.24)) 1 1;
    padding: 15px 13px;

    .tabs {
        margin-bottom: 16px;

        .tab-item {
            margin-right: 13px;
            display: inline-block;
            width: 112px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            border-radius: 2px;
            background: rgba(78, 90, 105, 0.3);
            color: #fff;
            cursor: pointer;
        }

        .tab-active {
            box-shadow: inset 0px 0px 16px 0px rgba(37, 167, 255, 0.8);
            // border: 1px solid rgba(37, 167, 255, 0.6);
            background: #074e9e;
        }
    }

    .id-tag {
        margin-bottom: 14px;

        .tag-all {
            margin-bottom: 4px;
            cursor: pointer;
        }

        .tag-list {
            display: flex;

            .tag-item {
                cursor: pointer;
            }

            .tag-img {
                width: 14px;
                margin-right: 2px;
                position: relative;
                top: -1px;
            }

            .today {
                text-indent: 18px;
                margin-top: 6px;
                font-size: 12px;
                color: #ffffff;
                opacity: 0.6;
            }
        }
    }

    .build-base {
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);

        .notice {
            margin-bottom: 8px;

            .icon-img {
                width: 16px;
                margin-right: 4px;
                position: relative;
                top: -2px;
            }
        }
    }

    .build-top {
        margin-bottom: 10px;
    }

    .build-name {
        cursor: pointer;
        font-size: 22px;
        font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
        font-weight: 500;
        color: #ffffff;
        background: linear-gradient(180deg, #fdffff 0%, #6ab3f5 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .dropdown-icon {
        width: 16px;
        height: 16px;
        margin-left: 8px;
        display: inline-block;

        &.opend {
            transform: rotateX(180deg);
        }
    }

    .options {
        position: absolute;
        top: 36px;
        left: 0;
        padding-top: 8px;
        max-height: 368px;
        width: 230px;
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 111;

        // border-right: 1px solid rgba(102, 225, 223, 0.6);
        /*滚动条样式*/
        &::-webkit-scrollbar {
            display: none;
        }

        // &::-webkit-scrollbar-thumb {
        //     border-radius: 10px;
        //     -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.6);
        //     background: rgba(255, 255, 255, 0.6);
        // }
        // &::-webkit-scrollbar-track {
        //     -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        //     border-radius: 0;
        //     background: rgba(0, 0, 0, 0.1);
        // }
        .option {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            box-sizing: border-box;
            background: rgba(5, 43, 62, 0.8);
            border: 1px solid rgba(37, 167, 255, 0.6);
            backdrop-filter: blur(4px);
            border-top: none;
            padding-left: 12px;
            line-height: 32px;
            font-size: 14px;
            font-family: AlibabaPuHuiTi-Bold;
            color: #fff;
            cursor: pointer;

            &.hover {
                box-shadow: inset 0px 0px 16px 0px rgba(40, 167, 255, 0.8);
            }

            &:first-child {
                border-top: 1px solid rgba(37, 167, 255, 0.6);
            }
        }
    }

    .tags {
        margin-bottom: 12px;
        display: flex;

        .tag-img {
            display: inline-block;
            margin-right: 4px;
            width: 16px;
            vertical-align: middle;
        }

        .tag-all {
            margin-right: 14px;
        }

        .tag-row {
            margin-bottom: 8px;
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
        }

        .item-card {
            width: 46%;
        }

        .tag-list {
            flex: 1;
        }

        .label-img {
            width: 16px;
            display: inline-block;
            margin-right: 4px;
        }

        .unknow-img {
            width: 28px;
            display: inline-block;
            margin-right: 4px;
        }

        .tag-item {
            font-size: 14px;
            cursor: pointer;
            padding: 0 8px;
            display: flex;
            align-items: center;
            &.active{
                background: rgba(255,255,255,0.16);
                border-radius: 8px;
            }

            .color-item {
                display: inline-block;
                vertical-align: middle;
                width: 12px;
                height: 12px;
                border-radius: 2px;
                margin-right: 5px;
            }

            .color1 {
                background: #5cffb5;
            }

            .color2 {
                background: #4c99ff;
            }

            .color3 {
                background: #eaeaea;
            }

            .color4 {
                background: #5ce1ff;
            }

            .color5 {
                background: #ffd948;
            }
        }
    }
}

.build-list {
    flex: 1;
    overflow-y: scroll;
    padding: 10px 0;

    .build-title {
        font-size: 20px;
        font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
        font-weight: 500;
        color: #ffffff;
        background: linear-gradient(180deg, #fdffff 0%, #6ab3f5 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 4px;
    }

    .build-item {
        margin-bottom: 5px;
    }

    .house-list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .house-name {
            font-size: 20px;
            font-family: D-DIN-PRO-Bold, D-DIN-PRO;
            font-weight: bold;
            margin-bottom: 6px;
        }

        .house-num {
            font-size: 14px;
            position: relative;
            font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;

            .num-img {
                display: inline-block;
                position: relative;
                top: -2px;
                width: 15px;
                margin-right: 6px;
            }
        }

        .house-num1 {
            font-size: 14px;
            font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
            margin-bottom: 4px;

            .num-img {
                display: inline-block;
                position: relative;
                top: -2px;
                width: 15px;
                margin-right: 6px;
            }
        }

        .check-num {
            font-size: 12px;
            padding-bottom: 4px;

            .check-text {
                margin-left: 1px;
                background: linear-gradient(180deg, #fdffff 0%, #6ab3f5 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        .sign-num {
            font-size: 14px;
            font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
            color: #5cffb5;

            .sign-img {
                position: relative;
                top: -2px;
                width: 15px;
                margin-right: 6px;
            }
        }

        .item-row {
            margin-bottom: 10px;
        }

        .item-row1 {
            display: flex;
            font-size: 14px;
            height: 26px;

            .work {
                line-height: 26px;
                font-weight: 500;
                color: #ffab30;
                // margin-bottom: 5px;
                margin-right: 16px;
            }

            .warn {
                line-height: 26px;
                font-weight: 500;
                color: #ff7a7a;
            }

            .num-txt {
                position: relative;
                top: 2px;
            }
        }

        .todo-img {
            display: inline-block;
            margin-right: 6px;
            width: 14px;
        }

        .house-item {
            cursor: pointer;
            margin-right: 6px;
            padding: 8px 12px;
            border-radius: 8px;
            margin-bottom: 6px;
            box-sizing: border-box;
            position: relative;

            .item-label {
                position: absolute;
                top: 0;
                right: 0;
                width: 48px;
                height: 32px;
                background: rgba(224, 224, 224, 0.16);
                border-radius: 0px 2px 0px 8px;
                display: flex;
                justify-content: center;
                align-items: center;

                .label-icon {
                    width: 16px;
                    display: inline-block;
                }

                .unknow-icon {
                    width: 28px;
                    display: inline-block;
                }
            }
        }

        @media (max-width: 2559px) {
            .house-item {
                width: calc(33.33% - 6px);

                &:nth-child(3n) {
                    margin-right: 0;
                }
            }
        }

        @media (min-width: 2560px) {
            .house-item {
                width: calc(25% - 6px);

                &:nth-child(4n) {
                    margin-right: 0;
                }
            }
        }

        .type1 {
            background: rgba(9, 106, 62, 0.16);
            border: 1px solid rgba(255, 255, 255, 0.24);
        }

        .type2 {
            background: rgba(76, 153, 255, 0.16);
            border: 1px solid rgba(255, 255, 255, 0.24);
        }

        .type3 {
            background: rgba(234, 234, 234, 0.16);
            border: 1px solid rgba(255, 255, 255, 0.24);
        }

        .type4 {
            background: rgba(92, 225, 255, 0.16);
            border: 1px solid rgba(255, 255, 255, 0.24);
        }

        .type5 {
            background: rgba(234, 234, 234, 0.08);
            border: 1px solid rgba(255, 255, 255, 0.12);
        }

        .type6 {
            background: rgba(92, 255, 108, 0.16);
            border: 1px solid rgba(255, 255, 255, 0.24);
        }

        .active1 {
            box-shadow: inset 0px 1px 16px 0px rgba(92, 255, 181, 0.8);
        }

        .active2 {
            box-shadow: inset 0px 1px 16px 0px rgba(37, 167, 255, 0.8);
        }

        .active3 {
            box-shadow: inset 0px 1px 16px 0px rgba(234, 234, 234, 0.8);
        }

        .active4 {
            box-shadow: inset 0px 1px 16px 0px rgba(163, 218, 254, 0.8);
        }

        .active5 {
            box-shadow: inset 0px 0px 16px 0px rgba(242, 242, 242, 0.5);
        }

        .active6 {
            box-shadow: inset 0px 1px 16px 0px #5CFF6C;
        }
    }

    /*滚动条样式*/
    &::-webkit-scrollbar {
        width: 2px;
        /*height: 4px;*/
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.6);
        background: rgba(255, 255, 255, 0.6);
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
    }
}