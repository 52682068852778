.community-background {
    height:calc(100vh - 92px) ;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 0;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    // 整体背景
    .full-bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;
    }

    .bg-wrap {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        background-color: #0a1e32;
        z-index: 0;
        width: 1926px;
        // height: 100%;
        height: 1083px;

        .bg-img-wrap {
            height: 100%;
            width: 100%;

            .bg {
                height: 100%;
                width: 100%;
            }
        }
    }

    .bg-left {
        position: absolute;
        left: 0;
        width: 35%;
        background: linear-gradient(
            270deg,
            rgba(9, 33, 65, 0.1) 0%,
            rgba(8, 22, 43, 0.8) 52%,
            #08162a 100%
        );
        // background: red;
        z-index: 1;
        height: 100%;
    }
    .bg-right {
        position: absolute;
        right: 0;
        width: 35%;
        background: linear-gradient(
            90deg,
            rgba(9, 33, 65, 0.1) 0%,
            rgba(8, 22, 43, 0.8) 52%,
            #08162a 100%
        );
        // background: red;
        z-index: 1;
        height: 100%;
    }
    .bg-top {
        position: absolute;
        top: 0;
        width: 100%;
        height: 93px;
        background: linear-gradient(360deg, rgba(5, 29, 48, 0) 0%, #0a1e32 100%);
        z-index: 1;
    }
    .points-wrap {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        // background-color: #0a1e32;
        z-index: 1;
        width: 1926px;
        height: 1083px;
        // height: 100%;

        // 标点
        .point {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            transform: translateX(-50%) translateY(-50%);
            z-index: 1;
            height: 44px;
            line-height: 44px;
            background: linear-gradient(180deg, #074e9e 0%, #074e9e 100%);
            box-shadow: inset 0px 1px 8px 0px rgba(37, 166, 255, 0.8);
            border-radius: 24px;
            backdrop-filter: blur(2px);
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            cursor: pointer;
            padding: 0 24px;
        }
        // 弹框
        .content {
            z-index: 9;
            display: flex;
            flex-direction: column;
            font-size: 0;
            border-radius: 4px;
            max-width: 160px;
            min-width: 160px;
            background: #092552;
            backdrop-filter: blur(2px);
    border: 1px solid #6699E1;

            .title {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 10px;
                width: 100%;
                height: 32px;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #fff;
                cursor: pointer;
                border-radius: 4px 4px 0 0;
                background: url("../../../../assets/remote/title.svg") no-repeat;
                background-position: center;
                background-size: 100%;
                overflow: hidden;

                > .text {
                    font-size: 18px;
                    font-family: PingFangTC-Semibold, PingFangTC;
                    font-weight: 600;
                    color: #ffffff;
                    background: linear-gradient(180deg, #ffffff 0%, #7ee7ff 100%);
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
            .main {
                > .item {
                    padding: 0 8px;
                    display: flex;
                    align-items: center;
                    height: 32px;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #fff;
                    line-height: 32px;
                    background: rgba(22, 96, 205, 0.6);
                    max-width: 169px;
                    overflow: hidden;

                    > img {
                        height: 24px;
                        width: 24px;
                        margin-right: 4px;
                    }
                    > .value {
                        font-size: 20px;
                        font-family: D-DIN-PRO-Medium, D-DIN-PRO;
                        font-weight: 600;
                        color: #fff;
                        line-height: 32px;
                        margin-left: auto;
                        margin-top: -2px;
                        background: linear-gradient(180deg, #ffffff 0%, #ff7167 100%);
                        -webkit-background-clip: text;
                        background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }
        }
    }
}
