.report-info {
    background: rgba(1, 24, 54, 0.97);
    box-shadow: inset 0px 1px 40px 0px #006091;
    border-radius: 8px;
    backdrop-filter: blur(2px);
    width: 48vw;
    height: 86vh;
    position: fixed;
    padding: 28px 24px;
    top: 0;
    right: 0;
    z-index: 11;
    .report-status {
        display: inline-block;
        position: relative;
        top: -2px;
        // height: 20px;
        padding: 4px 8px;
        border-radius: 4px;
        font-size: 14px;
    }
    .people-table {
        background: rgba(78, 90, 105, 0.6);
        padding: 6px 16px;
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        .people-col1 {
            width: 10%;
        }
        .people-col2 {
            width: 20%;
        }
        .people-col3 {
            width: 20%;
        }
        .people-col4 {
            width: 30%;
        }
        .people-col5 {
            width: 20%;
        }
        .people-td,
        .people_item {
            display: flex;
            margin-bottom: 12px;
        }
    }
    .report-title {
        margin-bottom: 24px;
        .title-text {
            font-size: 22px;
            font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
            color: #ffffff;
            line-height: 30px;
            background: linear-gradient(180deg, #fdffff 0%, #6ab3f5 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-right: 24px;
        }
    }
    .status1 {
        background: rgba(254, 185, 3, 0.12);
        color: #feb903;
    }
    .status2 {
        background: rgba(92, 255, 181, 0.2);
        color: #5cffb5;
    }
    .status3 {
        background: rgba(128, 128, 128, 0.2);
        color: #cccccc;
    }
    .status4 {
        background: rgba(255, 122, 122, 0.1);
        color: #ff7a7a;
    }
    .close-img {
        position: absolute;
        top: 18px;
        right: 16px;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
    .report-detail {
        height: 78vh;
        overflow-y: auto;
        .record-item {
            margin-bottom: 40px;
            position: relative;
            .item-tail {
                position: absolute;
                top: 25px;
                left: 9px;
                height: calc(100% + 21px);
                border-left: 1px solid #5ce1ff;
            }
            .record-time {
                font-size: 18px;
                font-weight: 500;
                color: #5ce1ff;
                margin-bottom: 16px;
                .dot-img {
                    margin-right: 16px;
                    width: 20px;
                    display: inline-block;
                }
            }
            .record-content {
                background: rgba(78, 90, 105, 0.3);
                border-radius: 4px;
                font-size: 16px;
                color: #ffffff;
                padding: 16px;
                margin-left: 38px;
                .record-row {
                    margin-bottom: 8px;
                }
                .row-flex {
                    display: flex;
                    flex-wrap: wrap;
                }
                .flex-item {
                    width: 50%;
                }
            }
        }
        /*滚动条样式*/
        &::-webkit-scrollbar {
            width: 4px;
            /*height: 4px;*/
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.6);
            background: rgba(255, 255, 255, 0.6);
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            border-radius: 0;
            background: rgba(0, 0, 0, 0.1);
        }
    }
}
