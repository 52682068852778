.community {
    height: 100%;
    width: 100%;
    position: relative;
    > .header {
        box-sizing: border-box;
        width: 100%;
        height: 93px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
        position: relative;
        .back {
            display: flex;
            align-items: center;
            font-family: PangMenZhengDao;
            height: 27px;
            font-size: 21px;
            color: #ffffff;
            cursor: pointer;
            z-index: 9;
        }
        .title {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            text-align: center;
            height: 93px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            z-index: 0;
            > .text {
                height: 47px;
                font-size: 36px;
                font-family: YouSheBiaoTiHei;
                color: #ffffff;
                line-height: 47px;
                // letter-spacing: 2px;
            }

            .updateTime {
                height: 20px;
                font-size: 14px;
                font-weight: 400;
                color: #d0deee;
                line-height: 20px;
            }
        }
    }

    .left {
        position: absolute;
        left: 16px;
    }
    .right {
        position: absolute;
        width: 24vw;
        right: 16px;
        height: calc(100% - 16px);
        // overflow: hidden;
    }
}
