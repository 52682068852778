.container {
    width: 900px;
    z-index: 201;
    border-radius: 8px;
    background: rgba(1, 24, 54, 0.97);
    box-shadow: inset 0px 1px 40px 0px #006091;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .title {
        padding: 0 16px;
        height: 48px;
        font-size: 16px;
        display: flex;
        background: #074e9e;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px 8px 0 0;
        > div {
            display: flex;
            align-items: center;
            img {
                height: 24px;
                width: 24px;
                margin-left: 16px;
                cursor: pointer;
            }
            span {
                &:nth-of-type(1) {
                    margin-right: 16px;
                }
                &:nth-child(2) {
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 22px;
                    border-radius: 2px;
                }
            }
            .tag1 {
                padding: 2px 8px;
                background: #ff711f;
                -webkit-text-fill-color: #450000;
                border: 2px;
            }
            .tag2 {
                padding: 2px 8px;
                background: #feb903;
                -webkit-text-fill-color: #450000;
                border: 2px;
            }
            .tag3 {
                padding: 2px 8px;
                background: #fc5c5c;
                -webkit-text-fill-color: #450000;
                border: 2px;
            }
        }
    }
    .content {
        padding: 16px;
        font-size: 16px;
        background: rgba(2, 44, 99, 0.97);
        box-shadow: inset 0px 1px 40px 0px #006091;
        border-radius: 0 0 8px 8px;

        ul {
            background: linear-gradient(360deg, rgba(40, 84, 139, 0.8) 0%, rgba(42, 65, 112, 0.08) 100%);
            border-radius: 4px;
            padding: 24px;
            li {
                display: flex;
                align-items: center;
                line-height: 24px;
                font-size: 16px;
                .itemTitle {
                    color: #d0deee;
                }
                &:nth-child(2) {
                    margin: 16px 0px 0 0;
                }
                .toPage {
                    height: 24px;
                    width: 150px;
                    margin: 16px;
                    display: flex;
                    font-size: 16px;
                    align-items: center;
                    justify-content: space-around;
                    background: linear-gradient(180deg, #ddeaf8 0%, #26b6fb 100%);
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-weight: bold;
                    cursor: pointer;
                    img {
                        height: 16px;
                        width: 16px;
                    }
                }
                .tag {
                    font-size: 12px;
                    height: 24px;
                    width: 52px;
                    text-align: center;
                    border-radius: 2px;
                    margin-left: 16px;
                }
                .tag1 {
                    color: #feb903;
                    background-color: rgba(254, 185, 3, 0.2);
                }
                .tag2 {
                    color: #26a6ff;
                    background-color: rgba(128, 128, 128, 0.2);
                }
                .tag3 {
                    background: rgba(92, 255, 181, 0.2);
                    color: #10df9d;
                }
            }
            .phone {
                display: flex;
                div {
                    &:nth-child(2) {
                        margin-left: 300px;
                    }
                }
            }
            &:nth-child(2) {
                margin-top: 16px;
            }
        }
    }
    .logs {
        width: 932px;
        max-height: 822px;
        padding: 20px 24px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: rgba(1, 24, 54, 0.97);
        box-shadow: inset 0px 1px 40px 0px #006091;
        border-radius: 4px;
        overflow-y: auto;
        &::-webkit-scrollbar {
            display: none;
        }
        .logsTitle {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 33px;
            > div {
                display: flex;
                align-items: center;
                > div {
                    font-size: 22px;
                    line-height: 30px;
                    background: linear-gradient(180deg, #fdffff 0%, #6ab3f5 100%);
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-weight: bold;
                    margin-right: 16px;
                }
                .tag {
                    font-size: 14px;
                    line-height: 28px;
                    height: 28px;
                    width: 58px;
                    text-align: center;
                    border-radius: 2px;
                }
                .tag1 {
                    color: #feb903;
                    background-color: rgba(254, 185, 3, 0.2);
                }
                .tag2 {
                    color: white;
                    background-color: rgba(128, 128, 128, 0.2);
                }
                .tag3 {
                    background: rgba(92, 255, 181, 0.2);
                    color: #10df9d;
                }
            }
            img {
                height: 24px;
                width: 24px;
                cursor: pointer;
            }
        }
        .logitem {
            display: flex;
            .itemImg {
                display: flex;
                flex-direction: column;
                img {
                    height: 20px;
                    width: 20px;
                    margin-right: 16px;
                }
                .line {
                    background-color: #5ce1ff;
                    width: 1px;
                    flex: 1;
                    margin-left: 9px;
                }
            }
            .itemTitle {
                flex: 1;
                .logtitle {
                    font-size: 18px;
                    span {
                        font-size: 16px;
                    }
                }
                .itemCenter {
                    padding: 16px;
                    margin-top: 20px;
                    background: linear-gradient(360deg, rgba(34, 62, 97, 0.8) 0%, rgba(25, 37, 62, 0.08) 100%);
                    border-radius: 4px;
                    > div {
                        margin-bottom: 12px;
                        display: flex;
                        span {
                            min-width: 69px;
                        }
                        img {
                            height: 96px;
                            width: 96px;
                            margin-right: 17px;
                        }
                    }
                }
            }
        }
    }
}
