.ant-tooltip-content {
    background: rgba(5, 19, 50, 0.88);
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    border: 1px solid #4C99FF;
    border-radius: 8px;

    .ant-tooltip-arrow-content {
        background: rgba(5, 19, 50, 0.88);
        box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
        border: 1px solid #4C99FF;

        &::before {
            display: none;
        }
    }
    .ant-tooltip-inner{
        border-radius: 8px;
    }
}