.home {
    // background: url("../../assets/bgTwo.webp") no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;

    .header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 12px;
        height: 100px;
        .title {
            height: 47px;
            font-size: 36px;
            font-family: YouSheBiaoTiHei;
            color: #ffffff;
            line-height: 47px;
            // letter-spacing: 2px;
        }

        .updateTime {
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #d0deee;
            line-height: 20px;
        }
    }

    .body {
        flex: 1;
        padding: 0 16px 16px;
        .statistics-container {
            padding-right: 8px;
            .statistics-box {
                font-size: 12px;
                display: flex;
                align-items: center;
            }
            .statistics-color {
                height: 8px;
                width: 8px;
                background-color: #338bff;
                margin-right: 4px;
            }
            .statistics-color2 {
                height: 8px;
                width: 8px;
                background-color: #5ce1ff;
                margin-right: 4px;
            }
        }
    }

    .left {
        .title {
            font-family: PangMenZhengDao;
        }
    }
}
