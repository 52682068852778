.warningList {
    background: rgba(5, 25, 41, 0.9000);
    border-radius: 4px;
    backdrop-filter: blur(2px);
    height: 86vh;
    position: fixed;
    top: 93px;
    left: 50%;
    transform: translateX(-50%);

    z-index: 2;

    &.other {
        width: 76vw;
        left: 11.5vw;
    }

    .close-img {
        position: absolute;
        top: 12px;
        right: 16px;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }

    .btns {
        background: rgba(5, 25, 41, 0.9000);
        width: 100%;
        position: fixed;
        top: 48px;
        left: 0;
    }

    .days {
        border-radius: 8px;
        overflow: hidden;

        .day {
            width: 96px;
            line-height: 36px;
            text-align: center;
            background: #074E9E;
            border: 1px solid rgba(37, 167, 255, 0.6);
            backdrop-filter: blur(4px);
            color: #A3DAFE;
            font-size: 16px;

            &.active {
                background: #074E9E;
                box-shadow: inset 0px 0px 16px 0px rgba(37, 167, 255, 0.8);
                border: 1px solid rgba(37, 167, 255, 0.6);
                color: #FFFFFF;
            }
        }
    }

    .head {
        width: 100%;
        height: 48px;
        overflow-x: auto;
        border: 1px solid rgba(102, 225, 223, 0.6000);
        white-space: nowrap;

        .item {
            background-image: linear-gradient(180deg, #FFFFFF 0%, #7EE7FF 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

    }

    .header {
        .head {
            line-height: 48px;
            border-right: none;
            overflow-y: hidden;

            /*滚动条样式*/
            &::-webkit-scrollbar {
                height: 6px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 3px;
                -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.6);
                background: rgba(255, 255, 255, 0.6);
            }

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                border-radius: 0;
                background: rgba(0, 0, 0, 0.1);
            }
        }


    }

    .w-240 {
        width: 240px;
    }

    .list {
        overflow-y: auto;

        /*滚动条样式*/
        &::-webkit-scrollbar {
            width: 4px;
            /*height: 4px;*/
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.6);
            background: rgba(255, 255, 255, 0.6);
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            border-radius: 0;
            background: rgba(0, 0, 0, 0.1);
        }
    }
}

.list {
    .listItem {
        white-space: nowrap;
        overflow-y: auto;

        &::-webkit-scrollbar {
            height: 0;
        }
    }
}